import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { resolveRouterPath } from '../router';
import logo from '../../public/assets/icons/48x48.png';

import '@shoelace-style/shoelace/dist/components/button/button.js';
@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'SelfLock';

  @property({ type: Boolean}) enableBack: boolean = false;

  static styles = css`
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--app-color-primary);
      color: white;
      height: 4em;
      padding-top: 12px;
      margin-right: 16px;
      padding-bottom: 12px;
      z-index: 200;

      backdrop-filter: blur(5px);

      position: fixed;
      left: env(titlebar-area-x, 0);
      top: env(titlebar-area-y, 0);
      height: env(titlebar-area-height, 50px);
      width: env(titlebar-area-width, 100%);
      -webkit-app-region: drag;
    }

    header h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
    }

    nav a {
      margin-left: 10px;
    }

    #back-button-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 97%;
    }

    #title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 97%;
      text-align: center;
    }

    @media(prefers-color-scheme: light) {
      header {
        color: black;
      }

      nav a {
        color: initial;
      }
    }
  `;

  render() {
    return html`
      <header>
        <div id="back-button-block">
          <img src="${logo}" alt="SelfLock Logo" />
          <h1 id="title">${this.title}</h1>
          ${this.enableBack ? html`<sl-button href="${resolveRouterPath()}">Back</sl-button>` : null}
          ${!this.enableBack ? html`<sl-button href="${resolveRouterPath('about') }">About</sl-button>` : null}
        </div>
      </header>
    `;
  }
}
