import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {
    static styles = [
        styles,
        css`
    #welcomeBar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 0px;
    }

    #welcomeCard,
    .infoCard {
      padding: 5px;
      padding-top: 0px;
      width:95%;
      text-align:center;
    }

    sl-avatar::(base) {
        background-color: transparent;
    }

    sl-avatar {
        background-color:transparent !important;
    }

    sl-icon[status="user"] {
      color: var(--sl-color-primary-400);
    }

    sl-icon[status="online"] {
      color: var(--sl-color-success-400);
    }

    sl-icon[status="offline"] {
      color: var(--sl-color-danger-600);
    }

    sl-icon[status="unlock"] {
      color: var(--sl-color-success-400);
    }

    sl-icon[status="lock"] {
      color: var(--sl-color-danger-600);
    }

    sl-icon[status="nosensor"] {
      color: var(--sl-color-danger-600);
    }

    sl-icon[status="sensoractive"] {
      color: var(--sl-color-success-400);
    }

    sl-icon[status="sensorinactive"] {
      color: var(--sl-color-neutral-700);
    }

    sl-card::part(footer) {
      display: flex;
    }

    sl-button {
      margin: 2px;
    }

    @media(min-width: 750px) {
      sl-card {
        width: 70vw;
      }
    }

    .avatar-group sl-avatar:not(:first-of-type) {
      margin-left: -1rem;
    }

    .avatar-group sl-avatar::part(base) {
      border: solid 2px var(--sl-color-neutral-50);
      background-color: var(--sl-color-gray-300);
    }

    @media (horizontal-viewport-segments: 2) {
      #welcomeBar {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      #welcomeCard {
        margin-right: 64px;
      }
    }
  `];

    dialog = null;
    infos: Array<{
        personDistance: any;
        presentationMode: any;
        sensorConnected: any;
        occupied: any;
        loggedIn: any;
        id: any;
        status: string;
}> = [];
    @property()
    message = 'Welcome to SelfLock!';
    @property()
    socketState = '';
    UNIQUE_ID = "id" + Math.random().toString(16).slice(2);

    constructor() {
        super();
        var machines: any = null;
        try {
            machines = JSON.parse(localStorage.machines);
        } catch {
            machines = [];
        }
        machines.forEach((m: any) => this.infos.push({
            id: m.serverId,
            status: "offline",
            occupied: false,
            sensorConnected: false,
            loggedIn: false,
            presentationMode: false,
            personDistance: ""
        }));
        setInterval(() => {
            this.checkSocketState();
            this.checkDeviceStates();
        }, 1000);
        // @ts-ignore
        window.home = this;
        // status: "Unlocked",
        // status: "Locked",
    }

    checkSocketState() {
        // @ts-ignore
        this.socketState = window.socketState;
    }

    checkDeviceStates() {
        // @ts-ignore
        var ws: WebSocket = window?.ws;
        // @ts-ignore
        if (window?.socketState === "online") {
            if (ws) {
                this.infos.forEach(info => {
                    try {
                        var data = JSON.stringify({
                            client_id: this.UNIQUE_ID,
                            receiver_id: info.id,
                            message: "Status"
                        });
                        ws.send(data);
                    } catch {
                        info.status = "offline";
                    }
                });
            }
        }
    }

    updateClient(data : any) {
        var updateinfo = this.infos.filter(info => info.id === data?.client_id)[0];
        if (updateinfo) {
            if (data?.message === "Status offline") {
                updateinfo.status = "offline";
            } else if (data?.message === "Status" && data?.loggedIn) {
                updateinfo.status = "online";
            } else if (data?.message === "Status" && !data?.loggedIn) {
                updateinfo.status = "online";
            } else {
                updateinfo.status = "offline";
            }
            updateinfo.loggedIn = data?.loggedIn || false;
            updateinfo.occupied = data?.occupied || false;
            updateinfo.sensorConnected = data?.sensorConnected || false;
            updateinfo.presentationMode = data?.presentationMode || false;
            updateinfo.personDistance = data?.personDistance || false;
            console.log(updateinfo.status);
            this.requestUpdate();
        }
    }

    async firstUpdated() {
        console.log('Initialized');
    }

    share(event: { target: any; }) {
        var id = this.getId(event.target);
        if ((navigator as any).share) {
            (navigator as any).share({
                title: 'SelfLock.online',
                text: 'Share a device!',
                url: 'https://selflock.online/?id=' + encodeURIComponent(id),
            });
        }
    }

    // @ts-ignore
    getId(target) {
        return target.closest('.infoCard').id;
    }

    // @ts-ignore
    deleteMachine(event) {
        const cardId = this.getId(event.target); // ID des Elements, das gel�scht werden soll
        // Finde das entsprechende Infocard-Objekt im Array
        var index = this.infos.findIndex((info) => info.id === cardId);
        if (index !== -1) {
            // Entferne das Element aus dem Array
            this.infos.splice(index, 1);
            this.requestUpdate(); // Erzwinge ein erneutes Rendern
        }
        try {
            var machines = JSON.parse(localStorage.machines);
        } catch {
            machines = [];
        }
        // @ts-ignore
        index = machines.findIndex((machine) => machine.serverId === cardId);
        if (index !== -1) {
            // Entferne das Element aus dem Array
            machines.splice(index, 1);
            this.requestUpdate(); // Erzwinge ein erneutes Rendern
        }
        localStorage.machines = JSON.stringify(machines);
    }

    addMachine() {
        const dialog = this.shadowRoot?.querySelector('sl-dialog');
        const text = this.shadowRoot?.querySelector('#textGuid');
        // @ts-ignore
        text?.setCustomValidity('');
        // @ts-ignore
        text.value = "";
        // @ts-ignore
        text?.reportValidity();
        dialog?.show();
    }

    isValidGuid(string: string) {
        // Erstelle eine Variable f�r den regul�ren Ausdruck, der das Guid-Format �berpr�ft
        const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        // Gib true zur�ck, wenn der String dem regul�ren Ausdruck entspricht, sonst false
        return regex.test(string);
    }

    addMachineClose() {
        const text = this.shadowRoot?.querySelector('#textGuid');
        // @ts-ignore
        if (text?.value && this.isValidGuid(text.value)) {
            // @ts-ignore
            var serverId = text?.value;
            var machines
            try {
                var machines = JSON.parse(localStorage.machines);
            } catch {
                machines = [];
            }
            // @ts-ignore
            var alreadyAdded = machines.some(machine => {
                // @ts-ignore
                return machine.serverId === serverId;
            });
            if (!alreadyAdded) {
                machines.push({
                    // @ts-ignore
                    serverId: serverId
                });
                localStorage.machines = JSON.stringify(machines);
                this.infos.push({
                    id: serverId,
                    status: "offline",
                    occupied: false,
                    sensorConnected: false,
                    loggedIn: false,
                    presentationMode: false,
                    personDistance: ""
                });
                const dialog = this.shadowRoot?.querySelector('sl-dialog');
                dialog?.hide();
            } else {
                // @ts-ignore
                text?.setCustomValidity("machine already added!");
                // @ts-ignore
                text.reportValidity();
            }
        } else {
            // @ts-ignore
            text?.setCustomValidity("Must be a valid guid");
            // @ts-ignore
            text.reportValidity();
        }
        this.requestUpdate();
    }

    // @ts-ignore
    lockDevice(event) {
        const ServerId = this.getId(event.target);
        var data = JSON.stringify({
            client_id: this.UNIQUE_ID,
            receiver_id: ServerId,
            message: "Lock screen"
        });
        // @ts-ignore
        window.ws.send(data);
    }

    render() {
        return html`
    <app-header></app-header>
    <main>
      <sl-dialog label="Dialog" class="dialog-focus">
        <sl-input id="textGuid" autofocus placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx" clearable></sl-input>
        <sl-button slot="footer" variant="primary" @click="${this.addMachineClose}">Add</sl-button>
      </sl-dialog>
      <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.message}</h2>
        ${this.socketState === 'online'
                ? html`<sl-badge variant="success">Connected to service</sl-badge>`
                : html`<sl-badge variant="danger">Service or device is offline</sl-badge>`}   
            </div>
            <p>
              To add new devices, simply scan the QR code using your device camera <br>
              or click the Add button and enter your ID from the system tray program.<br>
            </p>
          </sl-card>
        ${this.renderInfoCards()} <!-- Hier rufen wir die Methode f�r die Infocards auf -->
        <sl-button variant="default" @click="${this.addMachine}">Add computer</sl-button>
      </div>
    </main>
  `;
    }

    // @ts-ignore
    getSensorAvatar(info) {
        if (info.status && info.status !== "offline") {
            if (!info.sensorConnected) {
                return html`<sl-avatar>
                                <sl-icon slot="icon" title="No Sensor connected" status="nosensor" name="shield-shaded"></sl-icon>
                            </sl-avatar>`;
            }
            if (info.presentationMode) {
                return html`<sl-avatar>
                                <sl-icon slot="icon" title="Sensor in presentation mode (inactive)" status="sensorinactive" name="shield-shaded"></sl-icon>
                            </sl-avatar>`;
            }
            return html`<sl-avatar>
                                <sl-icon slot="icon" title="Sensor is active" status="sensoractive" name="shield-shaded"></sl-icon>
                            </sl-avatar>`;
        }
        return html``
    }

    // @ts-ignore
    getOccupancyAvatar(info) {
        if (info.status && info.sensorConnected) {
            if (info.occupied) {
                return html`<sl-avatar>
                                <sl-icon slot="icon" title="Person detected" status="user" name="person-exclamation"></sl-icon>
                            </sl-avatar>`;
            }
            /*
            return html`<sl-avatar>
                           <sl-icon slot="icon" status="nouser" name="person-fill-slash"></sl-icon>
                        </sl-avatar>`;
            */
        }
        return html``;
    }

    // @ts-ignore
    getOnlineAvatar(info) {
        if (info.status && info.status !== "offline") {
            return html`<sl-avatar>
                <sl-icon slot="icon" title="Desktop app is online" status="online" name="globe"></sl-icon>
            </sl-avatar>`;
        }
        return html`<sl-avatar>
                       <sl-icon slot="icon" title="Desktop app is offline" status="offline" name="globe"></sl-icon>
                    </sl-avatar>`;
    }

    // @ts-ignore
    getLogonAvatar(info) {
        if (info.status && info.status !== "offline") {
            if (info.loggedIn) {
                return html`<sl-avatar>
                                <sl-icon slot="icon" title="Desktop screen is unlocked" status="unlock" name="unlock"></sl-icon>
                            </sl-avatar>`;
            } else {
                return html`<sl-avatar>
                                <sl-icon slot="icon" title="Desktop screen is locked" status="lock" name="lock"></sl-icon>
                            </sl-avatar>`;
            }
        }
        return html``;
    }

    // @ts-ignore
    getAvatars(info) {
        return html`
        <div class="avatar-group">
            ${this.getOnlineAvatar(info)}
            ${this.getLogonAvatar(info)}
            ${this.getSensorAvatar(info)}
            ${this.getOccupancyAvatar(info)}
        </div>
        `;
    }

    

    // @ts-ignore
    getDescription(info) {
        if (info.status && info.status !== "offline") {
            return html`
                Computer is online.
                ${info.loggedIn ? html`<br>Screen is currently unlocked.` : html`<br>Screen is currently locked`}
                ${info.sensorConnected ? html`<br>Sensor connected.` : html`<br>Sensor disconnected.`}
                ${info.presentationMode ? html`<br>App is in presentation mode.` : html`<br>App autolock is active.`}
                ${info.occupied ? html`<br>Person in range at ${info.personDistance} distance.` : html`<br>No person in range.`}
            `;
        }
        return html`Computer is offline.`;
    }

    renderInfoCards() {
        // Annahme: Du hast ein Array von Infocard-Objekten namens "infoCards"
        if (this.infos && this.infos.length > 0) {
            return this.infos.map((info) => html`
      <sl-card class="infoCard" id="${info.id}">
        <div slot="header">
          ${this.getAvatars(info)}
        </div>
        <p>${this.getDescription(info)}</p>
        <!-- Weitere Inhalte der Infocard hier einf�gen -->
        ${info.loggedIn ? html`<sl-button slot="footer" variant="danger" @click="${this.lockDevice}">Lock device</sl-button>` : null}
        <div slot="footer" style="width:100%;height:1px"></div>
        ${'share' in navigator
                    ? html`<sl-button slot="footer" variant="default" @click="${this.share}">Share</sl-button>`
                    : null}
        <sl-button slot="footer" variant="default" @click="${this.deleteMachine}">Delete</sl-button>
      </sl-card>
    `);
        } else {
            // Falls keine Infocards vorhanden sind
            return html`<p>Add a new device first</p>`;
        }
    }
}
